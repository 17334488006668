html {
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.showCookieConsentDesktopButton {
    border-radius: 0 10px 0 0;
    border: none;
    bottom: 5px;
    cursor: pointer;
    font-family: "regular";
    font-size: 12px;
    left: 0;
    padding: 5px 12px;
    position: fixed;
    z-index: 1000;
}

.showCookieConsentDesktopButton span {
    margin-left: 10px;
}

.showCookieConsentDesktopButtonMobile {
    font-size: 14px;
    padding: 10px 10px;
    width: 200px;
    bottom: 50px;
}
/* Necessary fix so that the elements behind the toast notification on desktop are still clickable */
#root > .r-pointerEvents-12vffkv > *,
#root > .r-12vffkv > * {
    pointer-events: none !important;
}
